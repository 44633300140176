import React from "react";
import SEO from "../components/functional/seo";
import Button from "../components/atoms/button";
import DesignMenu from "./design-menu.json";

function DesignNavigation() {
  const navigationMenu = DesignMenu.find((dm) => dm.slug === "navigation");
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Navigation" />
      <h2>Navigation</h2>
      <p className="design__text p2">
        Navigation areas include the header and footer, both of which include
        menus.
      </p>
      {navigationMenu.submenu.map((item) => (
        <Button
          className="shared__link"
          route={`/${item.slug}`}
          key={item.slug}>
          {item.title}
        </Button>
      ))}
    </div>
  );
}

export default DesignNavigation;
